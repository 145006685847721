:root {
  --background-dark: #111111;
  --background-light: #f0f0f0;
  --text-dark: white;
  --text-light: black;
  --accent-dark: #666666;
  --accent-light: #545654;
  --icon-color-dark: #666666;
  --icon-color-light: #666666;
  --icon-hover-dark: white;
  --icon-hover-light: black;
  --tooltip-background-dark: #545654;
  --tooltip-background-light: white;
  --title-color-dark: white; 
  --title-color-light: black; 
}

[data-theme="dark"] {
  --background-color: var(--background-dark);
  --text-color: var(--text-dark);
  --accent-color: var(--accent-dark);
  --icon-color: var(--icon-color-dark);
  --icon-hover: var(--icon-hover-dark);
  --tooltip-background: var(--tooltip-background-dark);
  --title-color: var(--title-color-dark); 
}

[data-theme="light"] {
  --background-color: var(--background-light);
  --text-color: var(--text-light);
  --accent-color: var(--accent-light);
  --icon-color: var(--icon-color-light);
  --icon-hover: var(--icon-hover-light);
  --tooltip-background: var(--tooltip-background-light);
  --title-color: var(--title-color-light); 
}

.home-container {
  position: relative;
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

.profile-image-container {
  position: relative;
  display: flex;
  bottom: 20px;
  width: 30%;
  padding: 20px;
}

.profile-image {
  width: 100%;
  object-fit: cover;
  border-radius: 900px 800px 100px 100px;
}

.introduction-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 20px;
  bottom: 20px;
  left: 30px;
}

.introduction-content {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: flex-start;
}

.title, .name, .subtitle, .description {
  text-align: left; 
  color: var(--text-color);
}

.description {
  font-size: clamp(1rem, 1.5vw, 1.18rem);
  line-height: 1.5;
  padding-left: 20px; 
  position: relative;
}

.title::before {
  content: "-";
  position: absolute;
  left: -50px;
  color: var(--accent-color);
  font-size: 9.5rem;
  line-height: 0;
  font-weight: 300;
}

.title {
  font-size: clamp(1.5rem, 2vw, 2.5rem); 
  font-weight: bold;
  line-height: 1.2;
  padding-left: 20px; 
}

.name {
  font-size: clamp(2rem, 3vw, 3rem); 
  font-weight: bold;
  color: var(--accent-color);
  line-height: 1.2;
  padding-left: 20px; 
}

.subtitle {
  font-size: clamp(1rem, 3vw, 3rem); 
  font-weight: bold;
  line-height: 1.2;
  padding-left: 20px; 
}

.icons-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  z-index: 20;
}

.icon-wrapper {
  position: relative;
}

.icon-link {
  color: var(--icon-color);
  transition: color 0.3s;
}

.icon-link:hover {
  color: var(--icon-hover);
}

.tooltip {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%) translateX(-10px);
  background-color: var(--tooltip-background);
  color: var(--text-color);
  padding: 12px 10px;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  white-space: nowrap;
  font-size: 0.875rem;
  width: max-content;
}

.icon-wrapper:hover .tooltip {
  opacity: 1;
  transform: translateY(-50%) translateX(-100%);
}
.icon {
  font-size: 50px; /* Varsayılan boyut */
}

@media (max-width: 768px) {
  .icon {
    font-size: 30px; /* Mobilde daha küçük boyut */
  }

  .home-container {
    flex-direction: column;
    padding-right: 20px;
    align-items: center; /* Sola hizalamak için eklendi */
    justify-content: center; /* Ortalamak için eklendi */

  }

  .profile-image-container {
    width: 50%; /* Mobilde profil fotoğrafının boyutunu küçültün */
    padding-bottom: 10px;
  }

  .introduction-container {
    width: 100%;
    align-items: center; /* Sola hizalamak için eklendi */
  }
  .introduction-content {
    align-items: center; /* Sola hizalamak için eklendi */
  }

  .profile-image {
    border-radius: 50%; /* Mobilde fotoğrafı yuvarlak yapın */
  }

  .icons-container {
    position: static; /* İkonları mobilde statik yapın */
    bottom: auto;
    flex-direction: row; /* İkonları satır olarak hizalayın */
    justify-content: center; /* İkonları sola hizalayın */
    gap: 10px;
  }

  .title::before {
    display: none; /* Küçük ekranlarda dash işaretini gizleyin */
  }
  .tooltip {
    display: none; /* Mobilde tooltips'i gizle */
  }
}
