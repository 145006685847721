:root {
  --background-dark: #111111;
  --background-light: #f0f0f0;
  --text-dark: white;
  --text-light: black;
  --accent-dark: #666666;
  --accent-light: #545654;
  --icon-color-dark: #666666;
  --icon-color-light: #666666;
  --icon-hover-dark: white;
  --icon-hover-light: black;
  --tooltip-background-dark: #545654;
  --tooltip-background-light: white;
  --title-color-dark: white; 
  --title-color-light: black; 
}

[data-theme="dark"] {
  --background-color: var(--background-dark);
  --text-color: var(--text-dark);
  --accent-color: var(--accent-dark);
  --icon-color: var(--icon-color-dark);
  --icon-hover: var(--icon-hover-dark);
  --tooltip-background: var(--tooltip-background-dark);
  --title-color: var(--title-color-dark); 
}

[data-theme="light"] {
  --background-color: var(--background-light);
  --text-color: var(--text-light);
  --accent-color: var(--accent-light);
  --icon-color: var(--icon-color-light);
  --icon-hover: var(--icon-hover-light);
  --tooltip-background: var(--tooltip-background-light);
  --title-color: var(--title-color-light); 
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  padding: 1rem;
  position: relative; 
}

.project-section {
  display: flex;
  align-items: center;
  margin-bottom: 3rem; 
}

.slider-container {
  flex: 1;
  margin-right: 1rem;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.portfolio-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  max-width: 50%;
  text-align: left;
  margin-bottom: 1rem;
}

.slider {
  width: 100%;
  margin-bottom: 1rem;
}
.github-link {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  color: #333; 
  text-decoration: none;
}
.github-link svg {
  margin-right: 8px; 
}
.github-link:hover {
  color: #6e5494; 
}

.portfolio-img {
  border-radius: 2.6rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: auto;
}


.portfolio-title1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--title-color);
  align-self: flex-start;
  font-weight: 600;
}


.portfolio-text {
  color: var(--text-color);
  font-size: 1.2rem;
  line-height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}


.portfolio-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--accent-color);
  align-self: flex-start;
  font-weight: 600;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 1;
}

.custom-arrow-left {
  left: 10px;
}

.custom-arrow-right {
  right: 10px;
}

.back-button {
  position: absolute;
  z-index: 1;
  color: var(--icon-color-dark);
  transition: color 0.3s;
  top: -80px;
}
/* Mobile-specific styles */
@media (max-width: 768px) {
  .slider-container {
    width: 100%;
    margin: 0 auto;
  }

  .portfolio-img {
    border-radius: 1rem; /* Reduce the border radius */
  }

  .portfolio-text-container {
    max-width: 100%;
    margin-top: 1rem;
    max-height: 100%;
  }

  .back-button {
    left: 5px;
  }

  .project-section {
    flex-direction: column;
    align-items: center;
  }
  .portfolio-title1, .portfolio-title {
    font-size: 1.2rem; /* Smaller title font size */
    line-height: 1.5rem;
  }

  .custom-arrow {
    /* Ok boyutlarını küçültmek için font-size ve padding ayarları */
    font-size: 20px; /* Ok boyutunu küçültme */
    padding: 0.1rem; /* Ok etrafındaki boşluğu küçültme */
  }

  .custom-arrow svg {
    width: 24px; /* Ok simgesinin genişliğini küçültme */
    height: 24px; /* Ok simgesinin yüksekliğini küçültme */
  }
  .portfolio-text {
    color: var(--text-color);
    font-size: 0.9rem;
    line-height: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* Metni 6 satırda kes */
    -webkit-box-orient: vertical;
    cursor: pointer; /* Tıklanabilir alanı belirtmek için */
  }
  
  /* Metin genişletildiğinde tüm metni göster */
  .portfolio-text.expanded {
    -webkit-line-clamp: unset; /* Kısıtlamayı kaldır */
    overflow: visible; /* Taşan içeriği göster */
    cursor: default; /* Varsayılan imleç */
  }
}
